/* eslint-disable */

import * as olxmlModule from 'ol/xml';
import * as olwebglModule from 'ol/webgl';
import * as olutilModule from 'ol/util';
import * as oltransformModule from 'ol/transform';
import * as oltilegridWMTSModule from 'ol/tilegrid/WMTS';
import * as oltilegridModule from 'ol/tilegrid';
import * as olstyleIconImageCacheModule from 'ol/style/IconImageCache';
import * as olsphereModule from 'ol/sphere';
import * as olsourcewmsModule from 'ol/source/wms';
import * as olsourcestaticModule from 'ol/source/static';
import * as olsourcemapguideModule from 'ol/source/mapguide';
import * as olsourcearcgisRestModule from 'ol/source/arcgisRest';
import * as olsourceWMTSModule from 'ol/source/WMTS';
import * as olsourceVectorModule from 'ol/source/Vector';
import * as olsourceTileModule from 'ol/source/Tile';
import * as olsourceRasterModule from 'ol/source/Raster';
import * as olsourceOSMModule from 'ol/source/OSM';
import * as olsourceModule from 'ol/source';
import * as olsourceImageModule from 'ol/source/Image';
import * as olsizeModule from 'ol/size';
import * as olrenderModule from 'ol/render';
import * as olrenderFeatureModule from 'ol/render/Feature';
import * as olprojproj4Module from 'ol/proj/proj4';
import * as olprojUnitsModule from 'ol/proj/Units';
import * as olprojModule from 'ol/proj';
import * as olloadingstrategyModule from 'ol/loadingstrategy';
import * as ollayerGroupModule from 'ol/layer/Group';
import * as olinteractiondefaultsModule from 'ol/interaction/defaults';
import * as olinteractionTranslateModule from 'ol/interaction/Translate';
import * as olinteractionSelectModule from 'ol/interaction/Select';
import * as olinteractionModifyModule from 'ol/interaction/Modify';
import * as olinteractionExtentModule from 'ol/interaction/Extent';
import * as olinteractionDrawModule from 'ol/interaction/Draw';
import * as olinteractionDragBoxModule from 'ol/interaction/DragBox';
import * as olinteractionDragAndDropModule from 'ol/interaction/DragAndDrop';
import * as olhasModule from 'ol/has';
import * as olgeomPolygonModule from 'ol/geom/Polygon';
import * as olformatfilterModule from 'ol/format/filter';
import * as olformatWFSModule from 'ol/format/WFS';
import * as olformatPolylineModule from 'ol/format/Polyline';
import * as olfeatureloaderModule from 'ol/featureloader';
import * as olextentModule from 'ol/extent';
import * as oleventsconditionModule from 'ol/events/condition';
import * as oleventsSnapEventModule from 'ol/events/SnapEvent';
import * as oleasingModule from 'ol/easing';
import * as olcoordinateModule from 'ol/coordinate';
import * as olcontroldefaultsModule from 'ol/control/defaults';
import * as olcolorlikeModule from 'ol/colorlike';
import * as olcolorModule from 'ol/color';
import * as olarrayModule from 'ol/array';
import * as olObservableModule from 'ol/Observable';
import * as olObjectModule from 'ol/Object';
import * as olImageModule from 'ol/Image';
import * as olGeolocationModule from 'ol/Geolocation';
import * as olCollectionModule from 'ol/Collection';
import $ol$tilegrid$WMTS from 'ol/tilegrid/WMTS';
import $ol$tilegrid$TileGrid from 'ol/tilegrid/TileGrid';
import $ol$style$Text from 'ol/style/Text';
import $ol$style$Style from 'ol/style/Style';
import $ol$style$Stroke from 'ol/style/Stroke';
import $ol$style$RegularShape from 'ol/style/RegularShape';
import $ol$style$Image from 'ol/style/Image';
import $ol$style$IconImageCache from 'ol/style/IconImageCache';
import $ol$style$Icon from 'ol/style/Icon';
import $ol$style$Fill from 'ol/style/Fill';
import $ol$style$Circle from 'ol/style/Circle';
import $ol$structs$LRUCache from 'ol/structs/LRUCache';
import $ol$source$Zoomify from 'ol/source/Zoomify';
import $ol$source$XYZ from 'ol/source/XYZ';
import $ol$source$WMTS from 'ol/source/WMTS';
import $ol$source$VectorTile from 'ol/source/VectorTile';
import $ol$source$Vector from 'ol/source/Vector';
import $ol$source$UrlTile from 'ol/source/UrlTile';
import $ol$source$UTFGrid from 'ol/source/UTFGrid';
import $ol$source$TileWMS from 'ol/source/TileWMS';
import $ol$source$TileJSON from 'ol/source/TileJSON';
import $ol$source$TileImage from 'ol/source/TileImage';
import $ol$source$TileDebug from 'ol/source/TileDebug';
import $ol$source$TileArcGISRest from 'ol/source/TileArcGISRest';
import $ol$source$Tile from 'ol/source/Tile';
import $ol$source$StadiaMaps from 'ol/source/StadiaMaps';
import $ol$source$Source from 'ol/source/Source';
import $ol$source$Raster from 'ol/source/Raster';
import $ol$source$OSM from 'ol/source/OSM';
import $ol$source$OGCVectorTile from 'ol/source/OGCVectorTile';
import $ol$source$OGCMapTile from 'ol/source/OGCMapTile';
import $ol$source$ImageWMS from 'ol/source/ImageWMS';
import $ol$source$ImageStatic from 'ol/source/ImageStatic';
import $ol$source$ImageMapGuide from 'ol/source/ImageMapGuide';
import $ol$source$ImageCanvas from 'ol/source/ImageCanvas';
import $ol$source$ImageArcGISRest from 'ol/source/ImageArcGISRest';
import $ol$source$Image from 'ol/source/Image';
import $ol$source$IIIF from 'ol/source/IIIF';
import $ol$source$GeoTIFF from 'ol/source/GeoTIFF';
import $ol$source$DataTile from 'ol/source/DataTile';
import $ol$source$Cluster from 'ol/source/Cluster';
import $ol$source$CartoDB from 'ol/source/CartoDB';
import $ol$source$BingMaps from 'ol/source/BingMaps';
import $ol$renderer$webgl$TileLayer from 'ol/renderer/webgl/TileLayer';
import $ol$renderer$webgl$PointsLayer from 'ol/renderer/webgl/PointsLayer';
import $ol$renderer$canvas$VectorTileLayer from 'ol/renderer/canvas/VectorTileLayer';
import $ol$renderer$canvas$VectorLayer from 'ol/renderer/canvas/VectorLayer';
import $ol$renderer$canvas$VectorImageLayer from 'ol/renderer/canvas/VectorImageLayer';
import $ol$renderer$canvas$TileLayer from 'ol/renderer/canvas/TileLayer';
import $ol$renderer$canvas$ImageLayer from 'ol/renderer/canvas/ImageLayer';
import $ol$renderer$Composite from 'ol/renderer/Composite';
import $ol$render$canvas$Immediate from 'ol/render/canvas/Immediate';
import $ol$render$VectorContext from 'ol/render/VectorContext';
import $ol$render$Feature from 'ol/render/Feature';
import $ol$render$Event from 'ol/render/Event';
import $ol$proj$Projection from 'ol/proj/Projection';
import $ol$layer$WebGLTile from 'ol/layer/WebGLTile';
import $ol$layer$VectorTile from 'ol/layer/VectorTile';
import $ol$layer$VectorImage from 'ol/layer/VectorImage';
import $ol$layer$Vector from 'ol/layer/Vector';
import $ol$layer$Tile from 'ol/layer/Tile';
import $ol$layer$Layer from 'ol/layer/Layer';
import $ol$layer$Image from 'ol/layer/Image';
import $ol$layer$Heatmap from 'ol/layer/Heatmap';
import $ol$layer$Group from 'ol/layer/Group';
import $ol$layer$Graticule from 'ol/layer/Graticule';
import $ol$layer$BaseVector from 'ol/layer/BaseVector';
import $ol$layer$BaseTile from 'ol/layer/BaseTile';
import $ol$layer$BaseImage from 'ol/layer/BaseImage';
import $ol$layer$Base from 'ol/layer/Base';
import $ol$interaction$Translate from 'ol/interaction/Translate';
import $ol$interaction$Snap from 'ol/interaction/Snap';
import $ol$interaction$Select from 'ol/interaction/Select';
import $ol$interaction$Pointer from 'ol/interaction/Pointer';
import $ol$interaction$PinchZoom from 'ol/interaction/PinchZoom';
import $ol$interaction$PinchRotate from 'ol/interaction/PinchRotate';
import $ol$interaction$MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import $ol$interaction$Modify from 'ol/interaction/Modify';
import $ol$interaction$Link from 'ol/interaction/Link';
import $ol$interaction$KeyboardZoom from 'ol/interaction/KeyboardZoom';
import $ol$interaction$KeyboardPan from 'ol/interaction/KeyboardPan';
import $ol$interaction$Interaction from 'ol/interaction/Interaction';
import $ol$interaction$Extent from 'ol/interaction/Extent';
import $ol$interaction$Draw from 'ol/interaction/Draw';
import $ol$interaction$DragZoom from 'ol/interaction/DragZoom';
import $ol$interaction$DragRotateAndZoom from 'ol/interaction/DragRotateAndZoom';
import $ol$interaction$DragRotate from 'ol/interaction/DragRotate';
import $ol$interaction$DragPan from 'ol/interaction/DragPan';
import $ol$interaction$DragBox from 'ol/interaction/DragBox';
import $ol$interaction$DragAndDrop from 'ol/interaction/DragAndDrop';
import $ol$interaction$DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import $ol$interaction$DblClickDragZoom from 'ol/interaction/DblClickDragZoom';
import $ol$geom$SimpleGeometry from 'ol/geom/SimpleGeometry';
import $ol$geom$Polygon from 'ol/geom/Polygon';
import $ol$geom$Point from 'ol/geom/Point';
import $ol$geom$MultiPolygon from 'ol/geom/MultiPolygon';
import $ol$geom$MultiPoint from 'ol/geom/MultiPoint';
import $ol$geom$MultiLineString from 'ol/geom/MultiLineString';
import $ol$geom$LinearRing from 'ol/geom/LinearRing';
import $ol$geom$LineString from 'ol/geom/LineString';
import $ol$geom$GeometryCollection from 'ol/geom/GeometryCollection';
import $ol$geom$Geometry from 'ol/geom/Geometry';
import $ol$geom$Circle from 'ol/geom/Circle';
import $ol$format$filter$Within from 'ol/format/filter/Within';
import $ol$format$filter$Or from 'ol/format/filter/Or';
import $ol$format$filter$NotEqualTo from 'ol/format/filter/NotEqualTo';
import $ol$format$filter$Not from 'ol/format/filter/Not';
import $ol$format$filter$LessThanOrEqualTo from 'ol/format/filter/LessThanOrEqualTo';
import $ol$format$filter$LessThan from 'ol/format/filter/LessThan';
import $ol$format$filter$IsNull from 'ol/format/filter/IsNull';
import $ol$format$filter$IsLike from 'ol/format/filter/IsLike';
import $ol$format$filter$IsBetween from 'ol/format/filter/IsBetween';
import $ol$format$filter$Intersects from 'ol/format/filter/Intersects';
import $ol$format$filter$GreaterThanOrEqualTo from 'ol/format/filter/GreaterThanOrEqualTo';
import $ol$format$filter$GreaterThan from 'ol/format/filter/GreaterThan';
import $ol$format$filter$EqualTo from 'ol/format/filter/EqualTo';
import $ol$format$filter$During from 'ol/format/filter/During';
import $ol$format$filter$Disjoint from 'ol/format/filter/Disjoint';
import $ol$format$filter$DWithin from 'ol/format/filter/DWithin';
import $ol$format$filter$Contains from 'ol/format/filter/Contains';
import $ol$format$filter$Bbox from 'ol/format/filter/Bbox';
import $ol$format$XMLFeature from 'ol/format/XMLFeature';
import $ol$format$XML from 'ol/format/XML';
import $ol$format$WMTSCapabilities from 'ol/format/WMTSCapabilities';
import $ol$format$WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import $ol$format$WMSCapabilities from 'ol/format/WMSCapabilities';
import $ol$format$WKT from 'ol/format/WKT';
import $ol$format$WKB from 'ol/format/WKB';
import $ol$format$WFS from 'ol/format/WFS';
import $ol$format$TopoJSON from 'ol/format/TopoJSON';
import $ol$format$TextFeature from 'ol/format/TextFeature';
import $ol$format$Polyline from 'ol/format/Polyline';
import $ol$format$OSMXML from 'ol/format/OSMXML';
import $ol$format$MVT from 'ol/format/MVT';
import $ol$format$KML from 'ol/format/KML';
import $ol$format$JSONFeature from 'ol/format/JSONFeature';
import $ol$format$IIIFInfo from 'ol/format/IIIFInfo';
import $ol$format$IGC from 'ol/format/IGC';
import $ol$format$GeoJSON from 'ol/format/GeoJSON';
import $ol$format$GPX from 'ol/format/GPX';
import $ol$format$GMLBase from 'ol/format/GMLBase';
import $ol$format$GML32 from 'ol/format/GML32';
import $ol$format$GML3 from 'ol/format/GML3';
import $ol$format$GML2 from 'ol/format/GML2';
import $ol$format$GML from 'ol/format/GML';
import $ol$format$Feature from 'ol/format/Feature';
import $ol$format$EsriJSON from 'ol/format/EsriJSON';
import $ol$events$Target from 'ol/events/Target';
import $ol$events$Event from 'ol/events/Event';
import $ol$control$ZoomToExtent from 'ol/control/ZoomToExtent';
import $ol$control$ZoomSlider from 'ol/control/ZoomSlider';
import $ol$control$Zoom from 'ol/control/Zoom';
import $ol$control$ScaleLine from 'ol/control/ScaleLine';
import $ol$control$Rotate from 'ol/control/Rotate';
import $ol$control$OverviewMap from 'ol/control/OverviewMap';
import $ol$control$MousePosition from 'ol/control/MousePosition';
import $ol$control$FullScreen from 'ol/control/FullScreen';
import $ol$control$Control from 'ol/control/Control';
import $ol$control$Attribution from 'ol/control/Attribution';
import $ol$View from 'ol/View';
import $ol$VectorTile from 'ol/VectorTile';
import $ol$Tile from 'ol/Tile';
import $ol$Overlay from 'ol/Overlay';
import $ol$Observable from 'ol/Observable';
import $ol$Object from 'ol/Object';
import $ol$MapEvent from 'ol/MapEvent';
import $ol$MapBrowserEvent from 'ol/MapBrowserEvent';
import $ol$Map from 'ol/Map';
import $ol$Kinetic from 'ol/Kinetic';
import $ol$ImageTile from 'ol/ImageTile';
import $ol$Geolocation from 'ol/Geolocation';
import $ol$Feature from 'ol/Feature';
import $ol$DataTile from 'ol/DataTile';
import $ol$Collection from 'ol/Collection';
import * as MutilsModule from './facade/js/util/Utils';
import * as MunitsModule from './facade/js/units';
import * as MuipositionModule from './facade/js/ui/position';
import * as MtoastModule from './facade/js/toast';
import * as MtemplateModule from './facade/js/util/Template';
import * as MstyletextoverflowModule from './facade/js/style/Textoverflow';
import * as MstylestateModule from './facade/js/style/State';
import * as MstylequantificationModule from './facade/js/style/Quantification';
import * as MstylepatternModule from './facade/js/style/Pattern';
import * as MstyleformModule from './facade/js/style/Form';
import * as MstylechartModule from './facade/js/chart/types';
import * as MstylebaselineModule from './facade/js/style/Baseline';
import * as MstylealignModule from './facade/js/style/Align';
import * as MstyleProportionalModule from './facade/js/style/Proportional';
import * as MremoteModule from './facade/js/util/Remote';
import * as MparameterModule from './facade/js/parameter/parameter';
import * as MloadFilesModule from './facade/js/util/LoadFiles';
import * as MlayertypeModule from './facade/js/layer/Type';
import * as MlayerMVTModule from './facade/js/layer/MVT';
import * as MlayerMBTilesVectorModule from './facade/js/layer/MBTilesVector';
import * as MlanguageModule from './facade/js/i18n/language';
import * as MimplutilwmtscapabilitiesModule from './impl/ol/js/util/wmtscapabilities';
import * as MimplutilsModule from './impl/ol/js/util/Utils';
import * as MimplstylebuilderModule from './impl/ol/js/style/builder';
import * as MimplstyleChartModule from './impl/ol/js/style/Chart';
import * as MimploljspatchesModule from './impl/ol/js/patches';
import * as MimplhandlerFeatureModule from './impl/ol/js/handler/Feature';
import * as MimplcontrolScaleModule from './impl/ol/js/control/Scale';
import * as MgeomwkttypeModule from './facade/js/geom/WKT';
import * as MgeomwfstypeModule from './facade/js/geom/WFS';
import * as MgeomgeojsontypeModule from './facade/js/geom/GeoJSON';
import * as MgeomModule from './facade/js/geom/Geom';
import * as MfilterspatialModule from './facade/js/filter/Module';
import * as MfilterModule from './facade/js/filter/Filter';
import * as MevtModule from './facade/js/event/eventtype';
import * as MdialogModule from './facade/js/dialog';
import * as MParametersModule from './facade/js/parameter/Parameters';
import * as MModule from './facade/js/mapea';
import $M$window from './facade/js/util/Window';
import $M$utils from './facade/js/util/Utils';
import $M$ui$Panel from './facade/js/ui/Panel';
import $M$template from './facade/js/util/Template';
import $M$style$chart$Variable from './facade/js/chart/Variable';
import $M$style$Simple from './facade/js/style/Simple';
import $M$style$Proportional from './facade/js/style/Proportional';
import $M$style$Polygon from './facade/js/style/Polygon';
import $M$style$Point from './facade/js/style/Point';
import $M$style$Line from './facade/js/style/Line';
import $M$style$Heatmap from './facade/js/style/Heatmap';
import $M$style$Generic from './facade/js/style/Generic';
import $M$style$Font from './facade/js/style/Font';
import $M$style$FlowLine from './facade/js/style/FlowLine';
import $M$style$Feature from './facade/js/style/Feature';
import $M$style$Composite from './facade/js/style/Composite';
import $M$style$Cluster from './facade/js/style/Cluster';
import $M$style$Choropleth from './facade/js/style/Choropleth';
import $M$style$Chart from './facade/js/style/Chart';
import $M$style$Category from './facade/js/style/Category';
import $M$remote$Response from './facade/js/util/Response';
import $M$remote from './facade/js/util/Remote';
import $M$polyfill from './facade/js/util/polyfills';
import $M$parameter$xyz from './facade/js/parameter/xyz';
import $M$parameter$tms from './facade/js/parameter/tms';
import $M$parameter$mbtilesvector from './facade/js/parameter/mbtilesvector';
import $M$parameter$mbtiles from './facade/js/parameter/mbtiles';
import $M$parameter from './facade/js/parameter/parameter';
import $M$layer$XYZ from './facade/js/layer/XYZ';
import $M$layer$WMTS from './facade/js/layer/WMTS';
import $M$layer$WMS from './facade/js/layer/WMS';
import $M$layer$WFS from './facade/js/layer/WFS';
import $M$layer$Vector from './facade/js/layer/Vector';
import $M$layer$TMS from './facade/js/layer/TMS';
import $M$layer$OSM from './facade/js/layer/OSM';
import $M$layer$OGCAPIFeatures from './facade/js/layer/OGCAPIFeatures';
import $M$layer$MapLibre from './facade/js/layer/MapLibre';
import $M$layer$MVT from './facade/js/layer/MVT';
import $M$layer$MBTilesVector from './facade/js/layer/MBTilesVector';
import $M$layer$MBTiles from './facade/js/layer/MBTiles';
import $M$layer$LayerGroup from './facade/js/layer/LayerGroup';
import $M$layer$KML from './facade/js/layer/KML';
import $M$layer$GeoTIFF from './facade/js/layer/GeoTIFF';
import $M$layer$GeoJSON from './facade/js/layer/GeoJSON';
import $M$layer$GenericVector from './facade/js/layer/GenericVector';
import $M$layer$GenericRaster from './facade/js/layer/GenericRaster';
import $M$impl$utils from './impl/ol/js/util/Utils';
import $M$impl$util$wmtscapabilities from './impl/ol/js/util/wmtscapabilities';
import $M$impl$util$TextPath from './impl/ol/js/util/Textpath';
import $M$impl$style$Simple from './impl/ol/js/style/Simple';
import $M$impl$style$Polygon from './impl/ol/js/style/Polygon';
import $M$impl$style$Point from './impl/ol/js/style/Point';
import $M$impl$style$Path from './impl/ol/js/style/Path';
import $M$impl$style$Line from './impl/ol/js/style/Line';
import $M$impl$style$Icon from './impl/ol/js/style/Icon';
import $M$impl$style$Heatmap from './impl/ol/js/style/Heatmap';
import $M$impl$style$Generic from './impl/ol/js/style/Generic';
import $M$impl$style$FlowLine from './impl/ol/js/style/FlowLine';
import $M$impl$style$Feature from './impl/ol/js/style/Feature';
import $M$impl$style$Cluster from './impl/ol/js/style/Cluster';
import $M$impl$style$Chart from './impl/ol/js/style/Chart';
import $M$impl$style$Centroid from './impl/ol/js/style/Centroid';
import $M$impl$source$TileWMS from './impl/ol/js/source/TileWMS';
import $M$impl$source$ImageWMS from './impl/ol/js/source/ImageWMS';
import $M$impl$service$WFS from './impl/ol/js/service/WFS';
import $M$impl$service$OGCAPIFeatures from './impl/ol/js/service/OGCAPIFeatures';
import $M$impl$ol$js$projections from './impl/ol/js/projections';
import $M$impl$ol$js$patches from './impl/ol/js/patches';
import $M$impl$ol$js$ext$cspline from './impl/ol/js/ext/cspline';
import $M$impl$ol$js$ext$OLStyleFontSymbol from './impl/ol/js/ext/OLStyleFontSymbol';
import $M$impl$ol$js$ext$OLStyleFlowLine from './impl/ol/js/ext/OLStyleFlowLine';
import $M$impl$ol$js$ext$OLStyleFillPattern from './impl/ol/js/ext/OLStyleFillPattern';
import $M$impl$ol$js$ext$GeomUtils from './impl/ol/js/ext/GeomUtils';
import $M$impl$loader$WFS from './impl/ol/js/loader/WFS';
import $M$impl$loader$KML from './impl/ol/js/loader/KML';
import $M$impl$loader$JSONP from './impl/ol/js/loader/JSONP';
import $M$impl$loadFiles from './impl/ol/js/util/LoadFiles';
import $M$impl$layer$XYZ from './impl/ol/js/layer/XYZ';
import $M$impl$layer$WMTS from './impl/ol/js/layer/WMTS';
import $M$impl$layer$WMS from './impl/ol/js/layer/WMS';
import $M$impl$layer$WFS from './impl/ol/js/layer/WFS';
import $M$impl$layer$Vector from './impl/ol/js/layer/Vector';
import $M$impl$layer$TMS from './impl/ol/js/layer/TMS';
import $M$impl$layer$OSM from './impl/ol/js/layer/OSM';
import $M$impl$layer$OGCAPIFeatures from './impl/ol/js/layer/OGCAPIFeatures';
import $M$impl$layer$MapLibre from './impl/ol/js/layer/MapLibre';
import $M$impl$layer$MVT from './impl/ol/js/layer/MVT';
import $M$impl$layer$MBTilesVector from './impl/ol/js/layer/MBTilesVector';
import $M$impl$layer$MBTiles from './impl/ol/js/layer/MBTiles';
import $M$impl$layer$LayerGroup from './impl/ol/js/layer/LayerGroup';
import $M$impl$layer$KML from './impl/ol/js/layer/KML';
import $M$impl$layer$Heatmap from './impl/ol/js/layer/Heatmap';
import $M$impl$layer$GeoTIFF from './impl/ol/js/layer/GeoTIFF';
import $M$impl$layer$GeoJSON from './impl/ol/js/layer/GeoJSON';
import $M$impl$layer$GenericVector from './impl/ol/js/layer/GenericVector';
import $M$impl$layer$GenericRaster from './impl/ol/js/layer/GenericRaster';
import $M$impl$layer$Draw from './impl/ol/js/layer/Draw';
import $M$impl$layer$AnimatedCluster from './impl/ol/js/layer/AnimatedCluster';
import $M$impl$interaction$SelectCluster from './impl/ol/js/interaction/SelectedCluster';
import $M$impl$handler$Feature from './impl/ol/js/handler/Feature';
import $M$impl$format$XML from './impl/ol/js/format/XML';
import $M$impl$format$WMTSCapabilities from './impl/ol/js/format/WMTS';
import $M$impl$format$WMSCapabilities from './impl/ol/js/format/WMS';
import $M$impl$format$WKT from './impl/ol/js/format/WKT';
import $M$impl$format$KML from './impl/ol/js/format/KML';
import $M$impl$format$GeoJSON from './impl/ol/js/format/GeoJSON';
import $M$impl$format$GML from './impl/ol/js/format/GML';
import $M$impl$format$DescribeFeatureType from './impl/ol/js/format/wfs/DescribeFeatureType';
import $M$impl$control$ScaleLine from './impl/ol/js/control/ScaleLine';
import $M$impl$control$Scale from './impl/ol/js/control/Scale';
import $M$impl$control$Rotate from './impl/ol/js/control/Rotate';
import $M$impl$control$Panzoombar from './impl/ol/js/control/Panzoombar';
import $M$impl$control$Panzoom from './impl/ol/js/control/Panzoom';
import $M$impl$control$Location from './impl/ol/js/control/Location';
import $M$impl$control$GetFeatureInfo from './impl/ol/js/control/GetFeatureInfo';
import $M$impl$control$Attributions from './impl/ol/js/control/Attributions';
import $M$impl$View from './impl/ol/js/View';
import $M$impl$Style from './impl/ol/js/style/Style';
import $M$impl$RenderFeature from './impl/ol/js/feature/RenderFeature';
import $M$impl$Popup from './impl/ol/js/Popup';
import $M$impl$OLStyleStrokePattern from './impl/ol/js/ext/OLStyleStrokePattern';
import $M$impl$OLChart from './impl/ol/js/olchart/OLChart';
import $M$impl$Map from './impl/ol/js/Map';
import $M$impl$Layer from './impl/ol/js/layer/Layer';
import $M$impl$Label from './impl/ol/js/Label';
import $M$impl$GetCapabilities from './impl/ol/js/util/WMSCapabilities';
import $M$impl$Feature from './impl/ol/js/feature/Feature';
import $M$impl$Control from './impl/ol/js/control/Control';
import $M$i18n$plugins from './facade/js/i18n/plugins';
import $M$handler$Feature from './facade/js/handler/Feature';
import $M$handlebarshelpers from './facade/js/util/handlebarshelpers';
import $M$format$WKT from './facade/js/format/WKT';
import $M$format$GeoJSON from './facade/js/format/GeoJSON';
import $M$filter$Spatial from './facade/js/filter/Spatial';
import $M$filter$Function from './facade/js/filter/Function';
import $M$filter$Base from './facade/js/filter/Base';
import $M$facade$Base from './facade/js/Base';
import $M$exception from './facade/js/exception/exception';
import $M$evt$Listener from './facade/js/event/Listener';
import $M$evt$EventsManager from './facade/js/event/Manager';
import $M$control$ScaleLine from './facade/js/control/ScaleLine';
import $M$control$Scale from './facade/js/control/Scale';
import $M$control$Rotate from './facade/js/control/Rotate';
import $M$control$Panzoombar from './facade/js/control/Panzoombar';
import $M$control$Panzoom from './facade/js/control/Panzoom';
import $M$control$Location from './facade/js/control/Location';
import $M$control$GetFeatureInfo from './facade/js/control/GetFeatureInfo';
import $M$control$BackgroundLayers from './facade/js/control/BackgroundLayers';
import $M$control$Attributions from './facade/js/control/Attributions';
import $M$Tile from './facade/js/provider/Tile';
import $M$Style from './facade/js/style/Style';
import $M$RenderFeature from './facade/js/feature/RenderFeature';
import $M$Popup from './facade/js/Popup';
import $M$Plugin from './facade/js/Plugin';
import $M$Parameters from './facade/js/parameter/Parameters';
import $M$Object from './facade/js/Object';
import $M$Map from './facade/js/Map';
import $M$Layer from './facade/js/layer/Layer';
import $M$Label from './facade/js/Label';
import $M$Feature from './facade/js/feature/Feature';
import $M$Control from './facade/js/control/Control';
import $M$ClusteredFeature from './facade/js/feature/Clustered';
const M = window['M'] = {};
const ol = window['ol'] = {}
M.ClusteredFeature = $M$ClusteredFeature;
M.Control = $M$Control;
M.Feature = $M$Feature;
M.Label = $M$Label;
M.Layer = $M$Layer;
M.Map = $M$Map;
M.Object = $M$Object;
M.Parameters = $M$Parameters;
M.Parameters.parseBbox = MParametersModule.parseBbox;
M.Parameters.parseCenter = MParametersModule.parseCenter;
M.Parameters.parseContainer = MParametersModule.parseContainer;
M.Parameters.parseControls = MParametersModule.parseControls;
M.Parameters.parseGetFeatureInfo = MParametersModule.parseGetFeatureInfo;
M.Parameters.parseKML = MParametersModule.parseKML;
M.Parameters.parseLabel = MParametersModule.parseLabel;
M.Parameters.parseLayers = MParametersModule.parseLayers;
M.Parameters.parseMaxExtent = MParametersModule.parseMaxExtent;
M.Parameters.parseMaxZoom = MParametersModule.parseMaxZoom;
M.Parameters.parseMinZoom = MParametersModule.parseMinZoom;
M.Parameters.parseProjection = MParametersModule.parseProjection;
M.Parameters.parseResolutions = MParametersModule.parseResolutions;
M.Parameters.parseTicket = MParametersModule.parseTicket;
M.Parameters.parseViewExtent = MParametersModule.parseViewExtent;
M.Parameters.parseWMC = MParametersModule.parseWMC;
M.Parameters.parseWMS = MParametersModule.parseWMS;
M.Parameters.parseWMTS = MParametersModule.parseWMTS;
M.Parameters.parseZoom = MParametersModule.parseZoom;
M.Parameters.parseZoomConstrains = MParametersModule.parseZoomConstrains;
M.Plugin = $M$Plugin;
M.Popup = $M$Popup;
M.RenderFeature = $M$RenderFeature;
M.Style = $M$Style;
M.Tile = $M$Tile;
M.addQuickLayers = MModule.addQuickLayers;
M.config = MModule.config;
M.control = {};
M.control.Attributions = $M$control$Attributions;
M.control.BackgroundLayers = $M$control$BackgroundLayers;
M.control.GetFeatureInfo = $M$control$GetFeatureInfo;
M.control.Location = $M$control$Location;
M.control.Panzoom = $M$control$Panzoom;
M.control.Panzoombar = $M$control$Panzoombar;
M.control.Rotate = $M$control$Rotate;
M.control.Scale = $M$control$Scale;
M.control.ScaleLine = $M$control$ScaleLine;
M.dialog = {};
M.dialog.error = MdialogModule.error;
M.dialog.info = MdialogModule.info;
M.dialog.remove = MdialogModule.remove;
M.dialog.removeElement = MdialogModule.removeElement;
M.dialog.show = MdialogModule.show;
M.dialog.success = MdialogModule.success;
M.evt = {};
M.evt.ACTIVATED = MevtModule.ACTIVATED;
M.evt.ADDED_GEOTIFF = MevtModule.ADDED_GEOTIFF;
M.evt.ADDED_KML = MevtModule.ADDED_KML;
M.evt.ADDED_LAYER = MevtModule.ADDED_LAYER;
M.evt.ADDED_LAYERGROUP = MevtModule.ADDED_LAYERGROUP;
M.evt.ADDED_MAPLIBRE = MevtModule.ADDED_MAPLIBRE;
M.evt.ADDED_MBTILES = MevtModule.ADDED_MBTILES;
M.evt.ADDED_MBTILES_VECTOR = MevtModule.ADDED_MBTILES_VECTOR;
M.evt.ADDED_OGCAPIFEATURES = MevtModule.ADDED_OGCAPIFEATURES;
M.evt.ADDED_QUICK_LAYERS = MevtModule.ADDED_QUICK_LAYERS;
M.evt.ADDED_TMS = MevtModule.ADDED_TMS;
M.evt.ADDED_TO_LAYERGROUP = MevtModule.ADDED_TO_LAYERGROUP;
M.evt.ADDED_TO_MAP = MevtModule.ADDED_TO_MAP;
M.evt.ADDED_TO_PANEL = MevtModule.ADDED_TO_PANEL;
M.evt.ADDED_VECTOR_TILE = MevtModule.ADDED_VECTOR_TILE;
M.evt.ADDED_WFS = MevtModule.ADDED_WFS;
M.evt.ADDED_WMS = MevtModule.ADDED_WMS;
M.evt.ADDED_WMTS = MevtModule.ADDED_WMTS;
M.evt.ADDED_XYZ = MevtModule.ADDED_XYZ;
M.evt.CHANGE = MevtModule.CHANGE;
M.evt.CHANGE_PROJ = MevtModule.CHANGE_PROJ;
M.evt.CHANGE_STYLE = MevtModule.CHANGE_STYLE;
M.evt.CHANGE_ZOOM = MevtModule.CHANGE_ZOOM;
M.evt.CLICK = MevtModule.CLICK;
M.evt.COMPLETED = MevtModule.COMPLETED;
M.evt.DEACTIVATED = MevtModule.DEACTIVATED;
M.evt.DESTROY = MevtModule.DESTROY;
M.evt.EventsManager = $M$evt$EventsManager;
M.evt.HIDE = MevtModule.HIDE;
M.evt.HOVER_FEATURES = MevtModule.HOVER_FEATURES;
M.evt.LEAVE_FEATURES = MevtModule.LEAVE_FEATURES;
M.evt.LOAD = MevtModule.LOAD;
M.evt.Listener = $M$evt$Listener;
M.evt.MOVE = MevtModule.MOVE;
M.evt.MOVE_MOUSE = MevtModule.MOVE_MOUSE;
M.evt.REMOVED_FROM_MAP = MevtModule.REMOVED_FROM_MAP;
M.evt.REMOVED_LAYER = MevtModule.REMOVED_LAYER;
M.evt.SELECT_FEATURES = MevtModule.SELECT_FEATURES;
M.evt.SHOW = MevtModule.SHOW;
M.evt.UNSELECT_FEATURES = MevtModule.UNSELECT_FEATURES;
M.exception = $M$exception;
M.facade = {};
M.facade.Base = $M$facade$Base;
M.filter = {};
M.filter.AND = MfilterModule.AND;
M.filter.Base = $M$filter$Base;
M.filter.EQUAL = MfilterModule.EQUAL;
M.filter.Function = $M$filter$Function;
M.filter.GT = MfilterModule.GT;
M.filter.GTE = MfilterModule.GTE;
M.filter.LIKE = MfilterModule.LIKE;
M.filter.LT = MfilterModule.LT;
M.filter.LTE = MfilterModule.LTE;
M.filter.NOT = MfilterModule.NOT;
M.filter.OR = MfilterModule.OR;
M.filter.Spatial = $M$filter$Spatial;
M.filter.spatial = {};
M.filter.spatial.CONTAIN = MfilterspatialModule.CONTAIN;
M.filter.spatial.DISJOINT = MfilterspatialModule.DISJOINT;
M.filter.spatial.INTERSECT = MfilterspatialModule.INTERSECT;
M.filter.spatial.WITHIN = MfilterspatialModule.WITHIN;
M.filter.spatial.parseParamToGeometries = MfilterspatialModule.parseParamToGeometries;
M.format = {};
M.format.GeoJSON = $M$format$GeoJSON;
M.format.WKT = $M$format$WKT;
M.geom = {};
M.geom.geojson = {};
M.geom.geojson.type = {};
M.geom.geojson.type.GEOMETRY_COLLECTION = MgeomgeojsontypeModule.GEOMETRY_COLLECTION;
M.geom.geojson.type.LINE_STRING = MgeomgeojsontypeModule.LINE_STRING;
M.geom.geojson.type.MULTI_LINE_STRING = MgeomgeojsontypeModule.MULTI_LINE_STRING;
M.geom.geojson.type.MULTI_POINT = MgeomgeojsontypeModule.MULTI_POINT;
M.geom.geojson.type.MULTI_POLYGON = MgeomgeojsontypeModule.MULTI_POLYGON;
M.geom.geojson.type.POINT = MgeomgeojsontypeModule.POINT;
M.geom.geojson.type.POLYGON = MgeomgeojsontypeModule.POLYGON;
M.geom.parse = MgeomModule.parse;
M.geom.parseWFS = MgeomModule.parseWFS;
M.geom.wfs = {};
M.geom.wfs.type = {};
M.geom.wfs.type.LINE = MgeomwfstypeModule.LINE;
M.geom.wfs.type.MLINE = MgeomwfstypeModule.MLINE;
M.geom.wfs.type.MPOINT = MgeomwfstypeModule.MPOINT;
M.geom.wfs.type.MPOLYGON = MgeomwfstypeModule.MPOLYGON;
M.geom.wfs.type.POINT = MgeomwfstypeModule.POINT;
M.geom.wfs.type.POLYGON = MgeomwfstypeModule.POLYGON;
M.geom.wkt = {};
M.geom.wkt.type = {};
M.geom.wkt.type.CIRCLE = MgeomwkttypeModule.CIRCLE;
M.geom.wkt.type.GEOMETRY = MgeomwkttypeModule.GEOMETRY;
M.geom.wkt.type.GEOMETRY_COLLECTION = MgeomwkttypeModule.GEOMETRY_COLLECTION;
M.geom.wkt.type.LINEAR_RING = MgeomwkttypeModule.LINEAR_RING;
M.geom.wkt.type.LINE_STRING = MgeomwkttypeModule.LINE_STRING;
M.geom.wkt.type.MULTI_LINE_STRING = MgeomwkttypeModule.MULTI_LINE_STRING;
M.geom.wkt.type.MULTI_POINT = MgeomwkttypeModule.MULTI_POINT;
M.geom.wkt.type.MULTI_POLYGON = MgeomwkttypeModule.MULTI_POLYGON;
M.geom.wkt.type.POINT = MgeomwkttypeModule.POINT;
M.geom.wkt.type.POLYGON = MgeomwkttypeModule.POLYGON;
M.getQuickLayers = MModule.getQuickLayers;
M.handlebarshelpers = $M$handlebarshelpers;
M.handler = {};
M.handler.Feature = $M$handler$Feature;
M.i18n = {};
M.i18n.plugins = $M$i18n$plugins;
M.impl = {};
M.impl.Control = $M$impl$Control;
M.impl.Feature = $M$impl$Feature;
M.impl.GetCapabilities = $M$impl$GetCapabilities;
M.impl.Label = $M$impl$Label;
M.impl.Layer = $M$impl$Layer;
M.impl.Map = $M$impl$Map;
M.impl.OLChart = $M$impl$OLChart;
M.impl.OLStyleStrokePattern = $M$impl$OLStyleStrokePattern;
M.impl.Popup = $M$impl$Popup;
M.impl.RenderFeature = $M$impl$RenderFeature;
M.impl.Style = $M$impl$Style;
M.impl.View = $M$impl$View;
M.impl.control = {};
M.impl.control.Attributions = $M$impl$control$Attributions;
M.impl.control.GetFeatureInfo = $M$impl$control$GetFeatureInfo;
M.impl.control.Location = $M$impl$control$Location;
M.impl.control.Panzoom = $M$impl$control$Panzoom;
M.impl.control.Panzoombar = $M$impl$control$Panzoombar;
M.impl.control.Rotate = $M$impl$control$Rotate;
M.impl.control.Scale = $M$impl$control$Scale;
M.impl.control.Scale.formatLongNumber = MimplcontrolScaleModule.formatLongNumber;
M.impl.control.ScaleLine = $M$impl$control$ScaleLine;
M.impl.format = {};
M.impl.format.DescribeFeatureType = $M$impl$format$DescribeFeatureType;
M.impl.format.GML = $M$impl$format$GML;
M.impl.format.GeoJSON = $M$impl$format$GeoJSON;
M.impl.format.KML = $M$impl$format$KML;
M.impl.format.WKT = $M$impl$format$WKT;
M.impl.format.WMSCapabilities = $M$impl$format$WMSCapabilities;
M.impl.format.WMTSCapabilities = $M$impl$format$WMTSCapabilities;
M.impl.format.XML = $M$impl$format$XML;
M.impl.handler = {};
M.impl.handler.Feature = $M$impl$handler$Feature;
M.impl.handler.Feature.getFacadeFeature = MimplhandlerFeatureModule.getFacadeFeature;
M.impl.interaction = {};
M.impl.interaction.SelectCluster = $M$impl$interaction$SelectCluster;
M.impl.layer = {};
M.impl.layer.AnimatedCluster = $M$impl$layer$AnimatedCluster;
M.impl.layer.Draw = $M$impl$layer$Draw;
M.impl.layer.GenericRaster = $M$impl$layer$GenericRaster;
M.impl.layer.GenericVector = $M$impl$layer$GenericVector;
M.impl.layer.GeoJSON = $M$impl$layer$GeoJSON;
M.impl.layer.GeoTIFF = $M$impl$layer$GeoTIFF;
M.impl.layer.Heatmap = $M$impl$layer$Heatmap;
M.impl.layer.KML = $M$impl$layer$KML;
M.impl.layer.LayerGroup = $M$impl$layer$LayerGroup;
M.impl.layer.MBTiles = $M$impl$layer$MBTiles;
M.impl.layer.MBTilesVector = $M$impl$layer$MBTilesVector;
M.impl.layer.MVT = $M$impl$layer$MVT;
M.impl.layer.MapLibre = $M$impl$layer$MapLibre;
M.impl.layer.OGCAPIFeatures = $M$impl$layer$OGCAPIFeatures;
M.impl.layer.OSM = $M$impl$layer$OSM;
M.impl.layer.TMS = $M$impl$layer$TMS;
M.impl.layer.Vector = $M$impl$layer$Vector;
M.impl.layer.WFS = $M$impl$layer$WFS;
M.impl.layer.WMS = $M$impl$layer$WMS;
M.impl.layer.WMTS = $M$impl$layer$WMTS;
M.impl.layer.XYZ = $M$impl$layer$XYZ;
M.impl.loadFiles = $M$impl$loadFiles;
M.impl.loader = {};
M.impl.loader.JSONP = $M$impl$loader$JSONP;
M.impl.loader.KML = $M$impl$loader$KML;
M.impl.loader.WFS = $M$impl$loader$WFS;
M.impl.ol = {};
M.impl.ol.js = {};
M.impl.ol.js.ext = {};
M.impl.ol.js.ext.GeomUtils = $M$impl$ol$js$ext$GeomUtils;
M.impl.ol.js.ext.OLStyleFillPattern = $M$impl$ol$js$ext$OLStyleFillPattern;
M.impl.ol.js.ext.OLStyleFlowLine = $M$impl$ol$js$ext$OLStyleFlowLine;
M.impl.ol.js.ext.OLStyleFontSymbol = $M$impl$ol$js$ext$OLStyleFontSymbol;
M.impl.ol.js.ext.cspline = $M$impl$ol$js$ext$cspline;
M.impl.ol.js.patches = {};
M.impl.ol.js.patches.optionsFromCapabilities = MimploljspatchesModule.optionsFromCapabilities;
M.impl.ol.js.projections = $M$impl$ol$js$projections;
M.impl.service = {};
M.impl.service.OGCAPIFeatures = $M$impl$service$OGCAPIFeatures;
M.impl.service.WFS = $M$impl$service$WFS;
M.impl.source = {};
M.impl.source.ImageWMS = $M$impl$source$ImageWMS;
M.impl.source.TileWMS = $M$impl$source$TileWMS;
M.impl.style = {};
M.impl.style.Centroid = $M$impl$style$Centroid;
M.impl.style.Chart = $M$impl$style$Chart;
M.impl.style.Chart.extend = MimplstyleChartModule.extend;
M.impl.style.Chart.generateTextBarChart = MimplstyleChartModule.generateTextBarChart;
M.impl.style.Chart.generateTextCircleChart = MimplstyleChartModule.generateTextCircleChart;
M.impl.style.Chart.getTextData = MimplstyleChartModule.getTextData;
M.impl.style.Cluster = $M$impl$style$Cluster;
M.impl.style.Feature = $M$impl$style$Feature;
M.impl.style.FlowLine = $M$impl$style$FlowLine;
M.impl.style.Generic = $M$impl$style$Generic;
M.impl.style.Heatmap = $M$impl$style$Heatmap;
M.impl.style.Icon = $M$impl$style$Icon;
M.impl.style.Line = $M$impl$style$Line;
M.impl.style.Path = $M$impl$style$Path;
M.impl.style.Point = $M$impl$style$Point;
M.impl.style.Polygon = $M$impl$style$Polygon;
M.impl.style.Simple = $M$impl$style$Simple;
M.impl.style.builder = {};
M.impl.style.builder.getFill = MimplstylebuilderModule.getFill;
M.impl.style.builder.getFillPatern = MimplstylebuilderModule.getFillPatern;
M.impl.style.builder.getIconForm = MimplstylebuilderModule.getIconForm;
M.impl.style.builder.getIconSrc = MimplstylebuilderModule.getIconSrc;
M.impl.style.builder.getLabel = MimplstylebuilderModule.getLabel;
M.impl.style.builder.getLineStroke = MimplstylebuilderModule.getLineStroke;
M.impl.style.builder.getLineStyle = MimplstylebuilderModule.getLineStyle;
M.impl.style.builder.getLineText = MimplstylebuilderModule.getLineText;
M.impl.style.builder.getPointStyle = MimplstylebuilderModule.getPointStyle;
M.impl.style.builder.getPolygonStyle = MimplstylebuilderModule.getPolygonStyle;
M.impl.style.builder.getStroke = MimplstylebuilderModule.getStroke;
M.impl.style.builder.getStrokePatern = MimplstylebuilderModule.getStrokePatern;
M.impl.style.builder.iconCache = MimplstylebuilderModule.iconCache;
M.impl.util = {};
M.impl.util.TextPath = $M$impl$util$TextPath;
M.impl.util.wmtscapabilities = $M$impl$util$wmtscapabilities;
M.impl.util.wmtscapabilities = $M$impl$util$wmtscapabilities;
M.impl.util.wmtscapabilities.getLayers = MimplutilwmtscapabilitiesModule.getLayers;
M.impl.utils = $M$impl$utils;
M.impl.utils = $M$impl$utils;
M.impl.utils.geojsonTo4326 = MimplutilsModule.geojsonTo4326;
M.language = {};
M.language.addTranslation = MlanguageModule.addTranslation;
M.language.configuration = MlanguageModule.configuration;
M.language.getLang = MlanguageModule.getLang;
M.language.getTranslation = MlanguageModule.getTranslation;
M.language.getValue = MlanguageModule.getValue;
M.language.setLang = MlanguageModule.setLang;
M.layer = {};
M.layer.GenericRaster = $M$layer$GenericRaster;
M.layer.GenericVector = $M$layer$GenericVector;
M.layer.GeoJSON = $M$layer$GeoJSON;
M.layer.GeoTIFF = $M$layer$GeoTIFF;
M.layer.KML = $M$layer$KML;
M.layer.LayerGroup = $M$layer$LayerGroup;
M.layer.MBTiles = $M$layer$MBTiles;
M.layer.MBTilesVector = $M$layer$MBTilesVector;
M.layer.MBTilesVector.mode = MlayerMBTilesVectorModule.mode;
M.layer.MVT = $M$layer$MVT;
M.layer.MVT.mode = MlayerMVTModule.mode;
M.layer.MapLibre = $M$layer$MapLibre;
M.layer.OGCAPIFeatures = $M$layer$OGCAPIFeatures;
M.layer.OSM = $M$layer$OSM;
M.layer.TMS = $M$layer$TMS;
M.layer.Vector = $M$layer$Vector;
M.layer.WFS = $M$layer$WFS;
M.layer.WMS = $M$layer$WMS;
M.layer.WMTS = $M$layer$WMTS;
M.layer.XYZ = $M$layer$XYZ;
M.layer.type = {};
M.layer.type.GenericRaster = MlayertypeModule.GenericRaster;
M.layer.type.GenericVector = MlayertypeModule.GenericVector;
M.layer.type.GeoJSON = MlayertypeModule.GeoJSON;
M.layer.type.GeoTIFF = MlayertypeModule.GeoTIFF;
M.layer.type.KML = MlayertypeModule.KML;
M.layer.type.LayerGroup = MlayertypeModule.LayerGroup;
M.layer.type.MBTiles = MlayertypeModule.MBTiles;
M.layer.type.MBTilesVector = MlayertypeModule.MBTilesVector;
M.layer.type.MVT = MlayertypeModule.MVT;
M.layer.type.MapLibre = MlayertypeModule.MapLibre;
M.layer.type.OGCAPIFeatures = MlayertypeModule.OGCAPIFeatures;
M.layer.type.OSM = MlayertypeModule.OSM;
M.layer.type.TMS = MlayertypeModule.TMS;
M.layer.type.Vector = MlayertypeModule.Vector;
M.layer.type.WFS = MlayertypeModule.WFS;
M.layer.type.WMS = MlayertypeModule.WMS;
M.layer.type.WMTS = MlayertypeModule.WMTS;
M.layer.type.XYZ = MlayertypeModule.XYZ;
M.loadFiles = {};
M.loadFiles.addFileToMap = MloadFilesModule.addFileToMap;
M.loadFiles.loadFeaturesFromSource = MloadFilesModule.loadFeaturesFromSource;
M.map = MModule.map;
M.parameter = $M$parameter;
M.parameter = $M$parameter;
M.parameter.geojson = MparameterModule.geojson;
M.parameter.geotiff = MparameterModule.geotiff;
M.parameter.getBlobGeoTIFF = MparameterModule.getBlobGeoTIFF;
M.parameter.getCQLWFS = MparameterModule.getCQLWFS;
M.parameter.getConditionalOGC = MparameterModule.getConditionalOGC;
M.parameter.getDisplayInLayerSwitcherGeoTIFF = MparameterModule.getDisplayInLayerSwitcherGeoTIFF;
M.parameter.getDisplayInLayerSwitcherWMS = MparameterModule.getDisplayInLayerSwitcherWMS;
M.parameter.getDisplayInLayerSwitcherWMTS = MparameterModule.getDisplayInLayerSwitcherWMTS;
M.parameter.getExtraParameter = MparameterModule.getExtraParameter;
M.parameter.getExtractGeoJSON = MparameterModule.getExtractGeoJSON;
M.parameter.getExtractKML = MparameterModule.getExtractKML;
M.parameter.getExtractMBTilesVector = MparameterModule.getExtractMBTilesVector;
M.parameter.getExtractOGC = MparameterModule.getExtractOGC;
M.parameter.getExtractWFS = MparameterModule.getExtractWFS;
M.parameter.getFormatWMTS = MparameterModule.getFormatWMTS;
M.parameter.getGeometryWFS = MparameterModule.getGeometryWFS;
M.parameter.getIdsWFS = MparameterModule.getIdsWFS;
M.parameter.getLabelKML = MparameterModule.getLabelKML;
M.parameter.getLegendGeoJSON = MparameterModule.getLegendGeoJSON;
M.parameter.getLegendGeoTIFF = MparameterModule.getLegendGeoTIFF;
M.parameter.getLegendKML = MparameterModule.getLegendKML;
M.parameter.getLegendMBTiles = MparameterModule.getLegendMBTiles;
M.parameter.getLegendMBTilesVector = MparameterModule.getLegendMBTilesVector;
M.parameter.getLegendMVT = MparameterModule.getLegendMVT;
M.parameter.getLegendWFS = MparameterModule.getLegendWFS;
M.parameter.getLegendWMS = MparameterModule.getLegendWMS;
M.parameter.getLegendWMTS = MparameterModule.getLegendWMTS;
M.parameter.getMatrixSetWMTS = MparameterModule.getMatrixSetWMTS;
M.parameter.getMaxExtentMBTiles = MparameterModule.getMaxExtentMBTiles;
M.parameter.getMaxExtentMBTilesVector = MparameterModule.getMaxExtentMBTilesVector;
M.parameter.getMaxExtentWMS = MparameterModule.getMaxExtentWMS;
M.parameter.getMaxZoomLevelMBTiles = MparameterModule.getMaxZoomLevelMBTiles;
M.parameter.getNameGeoTIFF = MparameterModule.getNameGeoTIFF;
M.parameter.getNameKML = MparameterModule.getNameKML;
M.parameter.getNameMBTiles = MparameterModule.getNameMBTiles;
M.parameter.getNameMBTilesVector = MparameterModule.getNameMBTilesVector;
M.parameter.getNameMVT = MparameterModule.getNameMVT;
M.parameter.getNameTMS = MparameterModule.getNameTMS;
M.parameter.getNameWFS = MparameterModule.getNameWFS;
M.parameter.getNameWMS = MparameterModule.getNameWMS;
M.parameter.getNameWMTS = MparameterModule.getNameWMTS;
M.parameter.getNameXYZ = MparameterModule.getNameXYZ;
M.parameter.getNamespaceWFS = MparameterModule.getNamespaceWFS;
M.parameter.getNormalizeGeoTIFF = MparameterModule.getNormalizeGeoTIFF;
M.parameter.getOpacityMBTiles = MparameterModule.getOpacityMBTiles;
M.parameter.getOptionsWFS = MparameterModule.getOptionsWFS;
M.parameter.getOptionsWMS = MparameterModule.getOptionsWMS;
M.parameter.getOptionsWMTS = MparameterModule.getOptionsWMTS;
M.parameter.getProjectionGeoTIFF = MparameterModule.getProjectionGeoTIFF;
M.parameter.getQueryableWMS = MparameterModule.getQueryableWMS;
M.parameter.getQueryableWMTS = MparameterModule.getQueryableWMTS;
M.parameter.getSourceGeoJSON = MparameterModule.getSourceGeoJSON;
M.parameter.getSourceMBTiles = MparameterModule.getSourceMBTiles;
M.parameter.getSourceMBTilesVector = MparameterModule.getSourceMBTilesVector;
M.parameter.getStyleGeoJSON = MparameterModule.getStyleGeoJSON;
M.parameter.getStyleMBTilesVector = MparameterModule.getStyleMBTilesVector;
M.parameter.getStyleOGC = MparameterModule.getStyleOGC;
M.parameter.getStyleWFS = MparameterModule.getStyleWFS;
M.parameter.getTileLoadFunctionMBTiles = MparameterModule.getTileLoadFunctionMBTiles;
M.parameter.getTileLoadFunctionMBTilesVector = MparameterModule.getTileLoadFunctionMBTilesVector;
M.parameter.getTileSizeMBTiles = MparameterModule.getTileSizeMBTiles;
M.parameter.getTileSizeMBTilesVector = MparameterModule.getTileSizeMBTilesVector;
M.parameter.getTiledWMS = MparameterModule.getTiledWMS;
M.parameter.getTransparentGeoTIFF = MparameterModule.getTransparentGeoTIFF;
M.parameter.getTransparentMBTiles = MparameterModule.getTransparentMBTiles;
M.parameter.getTransparentWMS = MparameterModule.getTransparentWMS;
M.parameter.getTransparentWMTS = MparameterModule.getTransparentWMTS;
M.parameter.getType = MparameterModule.getType;
M.parameter.getURLGeoJSON = MparameterModule.getURLGeoJSON;
M.parameter.getURLGeoTIFF = MparameterModule.getURLGeoTIFF;
M.parameter.getURLKML = MparameterModule.getURLKML;
M.parameter.getURLMBTiles = MparameterModule.getURLMBTiles;
M.parameter.getURLMBTilesVector = MparameterModule.getURLMBTilesVector;
M.parameter.getURLMVT = MparameterModule.getURLMVT;
M.parameter.getURLWFS = MparameterModule.getURLWFS;
M.parameter.getURLWMS = MparameterModule.getURLWMS;
M.parameter.getURLWMTS = MparameterModule.getURLWMTS;
M.parameter.getURLXYZSource = MparameterModule.getURLXYZSource;
M.parameter.getUseCapabilitiesWMS = MparameterModule.getUseCapabilitiesWMS;
M.parameter.getVersionWFS = MparameterModule.getVersionWFS;
M.parameter.getVersionWMS = MparameterModule.getVersionWMS;
M.parameter.getVisibilityGeoTIFF = MparameterModule.getVisibilityGeoTIFF;
M.parameter.getVisibilityKML = MparameterModule.getVisibilityKML;
M.parameter.getVisibilityMBTiles = MparameterModule.getVisibilityMBTiles;
M.parameter.getVisibilityMBTilesVector = MparameterModule.getVisibilityMBTilesVector;
M.parameter.getVisibilityWMS = MparameterModule.getVisibilityWMS;
M.parameter.getVisibilityWMTS = MparameterModule.getVisibilityWMTS;
M.parameter.kml = MparameterModule.kml;
M.parameter.layer = MparameterModule.layer;
M.parameter.maxExtent = MparameterModule.maxExtent;
M.parameter.maxZoom = MparameterModule.maxZoom;
M.parameter.mbtiles = $M$parameter$mbtiles;
M.parameter.mbtiles = MparameterModule.mbtiles;
M.parameter.mbtilesvector = $M$parameter$mbtilesvector;
M.parameter.mbtilesvector = MparameterModule.mbtilesvector;
M.parameter.minZoom = MparameterModule.minZoom;
M.parameter.mvt = MparameterModule.mvt;
M.parameter.ogcapifeatures = MparameterModule.ogcapifeatures;
M.parameter.projection = MparameterModule.projection;
M.parameter.resolutions = MparameterModule.resolutions;
M.parameter.tms = $M$parameter$tms;
M.parameter.tms = MparameterModule.tms;
M.parameter.wfs = MparameterModule.wfs;
M.parameter.wms = MparameterModule.wms;
M.parameter.wmts = MparameterModule.wmts;
M.parameter.xyz = $M$parameter$xyz;
M.parameter.xyz = MparameterModule.xyz;
M.parameter.zoom = MparameterModule.zoom;
M.polyfill = $M$polyfill;
M.proxy = MModule.proxy;
M.remote = $M$remote;
M.remote.Response = $M$remote$Response;
M.remote.get = MremoteModule.get;
M.remote.method = MremoteModule.method;
M.remote.post = MremoteModule.post;
M.style = {};
M.style.Category = $M$style$Category;
M.style.Chart = $M$style$Chart;
M.style.Choropleth = $M$style$Choropleth;
M.style.Cluster = $M$style$Cluster;
M.style.Composite = $M$style$Composite;
M.style.Feature = $M$style$Feature;
M.style.FlowLine = $M$style$FlowLine;
M.style.Font = $M$style$Font;
M.style.Generic = $M$style$Generic;
M.style.Heatmap = $M$style$Heatmap;
M.style.Line = $M$style$Line;
M.style.Point = $M$style$Point;
M.style.Polygon = $M$style$Polygon;
M.style.Proportional = $M$style$Proportional;
M.style.Proportional.getMinMaxValues = MstyleProportionalModule.getMinMaxValues;
M.style.Simple = $M$style$Simple;
M.style.align = {};
M.style.align.CENTER = MstylealignModule.CENTER;
M.style.align.JUSTIFY = MstylealignModule.JUSTIFY;
M.style.align.LEFT = MstylealignModule.LEFT;
M.style.align.RIGHT = MstylealignModule.RIGHT;
M.style.baseline = {};
M.style.baseline.ALPHABETIC = MstylebaselineModule.ALPHABETIC;
M.style.baseline.BOTTOM = MstylebaselineModule.BOTTOM;
M.style.baseline.HANGING = MstylebaselineModule.HANGING;
M.style.baseline.IDEOGRAPHIC = MstylebaselineModule.IDEOGRAPHIC;
M.style.baseline.MIDDLE = MstylebaselineModule.MIDDLE;
M.style.baseline.TOP = MstylebaselineModule.TOP;
M.style.chart = {};
M.style.chart.Variable = $M$style$chart$Variable;
M.style.chart.schemes = MstylechartModule.schemes;
M.style.chart.types = MstylechartModule.types;
M.style.form = {};
M.style.form.BAN = MstyleformModule.BAN;
M.style.form.BLAZON = MstyleformModule.BLAZON;
M.style.form.BUBBLE = MstyleformModule.BUBBLE;
M.style.form.CIRCLE = MstyleformModule.CIRCLE;
M.style.form.LOZENGE = MstyleformModule.LOZENGE;
M.style.form.MARKER = MstyleformModule.MARKER;
M.style.form.NONE = MstyleformModule.NONE;
M.style.form.SHIELD = MstyleformModule.SHIELD;
M.style.form.SIGN = MstyleformModule.SIGN;
M.style.form.SQUARE = MstyleformModule.SQUARE;
M.style.form.TRIANGLE = MstyleformModule.TRIANGLE;
M.style.pattern = {};
M.style.pattern.BRECCIA = MstylepatternModule.BRECCIA;
M.style.pattern.BRICK = MstylepatternModule.BRICK;
M.style.pattern.CAPS = MstylepatternModule.CAPS;
M.style.pattern.CEMETRY = MstylepatternModule.CEMETRY;
M.style.pattern.CHAOS = MstylepatternModule.CHAOS;
M.style.pattern.CHARACTER = MstylepatternModule.CHARACTER;
M.style.pattern.CIRCLE = MstylepatternModule.CIRCLE;
M.style.pattern.CLAY = MstylepatternModule.CLAY;
M.style.pattern.COAL = MstylepatternModule.COAL;
M.style.pattern.CONGLOMERATE = MstylepatternModule.CONGLOMERATE;
M.style.pattern.CROSS = MstylepatternModule.CROSS;
M.style.pattern.CROSSES = MstylepatternModule.CROSSES;
M.style.pattern.DOLOMITE = MstylepatternModule.DOLOMITE;
M.style.pattern.DOT = MstylepatternModule.DOT;
M.style.pattern.FLOODED = MstylepatternModule.FLOODED;
M.style.pattern.FOREST = MstylepatternModule.FOREST;
M.style.pattern.GRASS = MstylepatternModule.GRASS;
M.style.pattern.GRAVEL = MstylepatternModule.GRAVEL;
M.style.pattern.HATCH = MstylepatternModule.HATCH;
M.style.pattern.HEXAGON = MstylepatternModule.HEXAGON;
M.style.pattern.ICON = MstylepatternModule.ICON;
M.style.pattern.IMAGE = MstylepatternModule.IMAGE;
M.style.pattern.NYLON = MstylepatternModule.NYLON;
M.style.pattern.PINE = MstylepatternModule.PINE;
M.style.pattern.PINES = MstylepatternModule.PINES;
M.style.pattern.ROCK = MstylepatternModule.ROCK;
M.style.pattern.ROCKS = MstylepatternModule.ROCKS;
M.style.pattern.SAND = MstylepatternModule.SAND;
M.style.pattern.SCRUB = MstylepatternModule.SCRUB;
M.style.pattern.SQUARE = MstylepatternModule.SQUARE;
M.style.pattern.SWAMP = MstylepatternModule.SWAMP;
M.style.pattern.TILE = MstylepatternModule.TILE;
M.style.pattern.TREE = MstylepatternModule.TREE;
M.style.pattern.VINE = MstylepatternModule.VINE;
M.style.pattern.WAVE = MstylepatternModule.WAVE;
M.style.pattern.WOVEN = MstylepatternModule.WOVEN;
M.style.quantification = {};
M.style.quantification.ARITHMETIC_PROGRESSION = MstylequantificationModule.ARITHMETIC_PROGRESSION;
M.style.quantification.EQUAL_INTERVAL = MstylequantificationModule.EQUAL_INTERVAL;
M.style.quantification.GEOMETRIC_PROGRESSION = MstylequantificationModule.GEOMETRIC_PROGRESSION;
M.style.quantification.JENKS = MstylequantificationModule.JENKS;
M.style.quantification.MEDIA_SIGMA = MstylequantificationModule.MEDIA_SIGMA;
M.style.quantification.QUANTILE = MstylequantificationModule.QUANTILE;
M.style.quantification.hasNegativeValue = MstylequantificationModule.hasNegativeValue;
M.style.quantification.uniqueArray = MstylequantificationModule.uniqueArray;
M.style.state = {};
M.style.state.DEFAULT = MstylestateModule.DEFAULT;
M.style.state.NEW = MstylestateModule.NEW;
M.style.state.SELECTED = MstylestateModule.SELECTED;
M.style.textoverflow = {};
M.style.textoverflow.ELLIPSIS = MstyletextoverflowModule.ELLIPSIS;
M.style.textoverflow.HIDDEN = MstyletextoverflowModule.HIDDEN;
M.style.textoverflow.VISIBLE = MstyletextoverflowModule.VISIBLE;
M.template = $M$template;
M.template.add = MtemplateModule.add;
M.template.compile = MtemplateModule.compile;
M.template.compileSync = MtemplateModule.compileSync;
M.template.get = MtemplateModule.get;
M.toast = {};
M.toast.error = MtoastModule.error;
M.toast.info = MtoastModule.info;
M.toast.remove = MtoastModule.remove;
M.toast.removeElement = MtoastModule.removeElement;
M.toast.show = MtoastModule.show;
M.toast.success = MtoastModule.success;
M.toast.warning = MtoastModule.warning;
M.ui = {};
M.ui.Panel = $M$ui$Panel;
M.ui.position = {};
M.ui.position.BL = MuipositionModule.BL;
M.ui.position.BR = MuipositionModule.BR;
M.ui.position.TL = MuipositionModule.TL;
M.ui.position.TR = MuipositionModule.TR;
M.units = {};
M.units.DOTS_PER_INCH = MunitsModule.DOTS_PER_INCH;
M.units.INCHES_PER_UNIT = MunitsModule.INCHES_PER_UNIT;
M.useproxy = MModule.useproxy;
M.utils = $M$utils;
M.utils.ObjectToArrayExtent = MutilsModule.ObjectToArrayExtent;
M.utils.addParameters = MutilsModule.addParameters;
M.utils.beautifyAttribute = MutilsModule.beautifyAttribute;
M.utils.beautifyAttributeName = MutilsModule.beautifyAttributeName;
M.utils.beautifyString = MutilsModule.beautifyString;
M.utils.bytesToBase64 = MutilsModule.bytesToBase64;
M.utils.concatUrlPaths = MutilsModule.concatUrlPaths;
M.utils.copyImageClipBoard = MutilsModule.copyImageClipBoard;
M.utils.decodeBase64 = MutilsModule.decodeBase64;
M.utils.decodeHtml = MutilsModule.decodeHtml;
M.utils.defineFunctionFromString = MutilsModule.defineFunctionFromString;
M.utils.dragElement = MutilsModule.dragElement;
M.utils.draggabillyElement = MutilsModule.draggabillyElement;
M.utils.draggabillyPlugin = MutilsModule.draggabillyPlugin;
M.utils.drawDynamicStyle = MutilsModule.drawDynamicStyle;
M.utils.enableTouchScroll = MutilsModule.enableTouchScroll;
M.utils.encodeBase64 = MutilsModule.encodeBase64;
M.utils.escapeJSCode = MutilsModule.escapeJSCode;
M.utils.escapeXSS = MutilsModule.escapeXSS;
M.utils.extend = MutilsModule.extend;
M.utils.extendsObj = MutilsModule.extendsObj;
M.utils.fillResolutions = MutilsModule.fillResolutions;
M.utils.findUrls = MutilsModule.findUrls;
M.utils.generateColorScale = MutilsModule.generateColorScale;
M.utils.generateIntervals = MutilsModule.generateIntervals;
M.utils.generateRandom = MutilsModule.generateRandom;
M.utils.generateResolutionsFromExtent = MutilsModule.generateResolutionsFromExtent;
M.utils.generateResolutionsFromScales = MutilsModule.generateResolutionsFromScales;
M.utils.getEnvolvedExtent = MutilsModule.getEnvolvedExtent;
M.utils.getImageMap = MutilsModule.getImageMap;
M.utils.getImageSize = MutilsModule.getImageSize;
M.utils.getOpacityFromRgba = MutilsModule.getOpacityFromRgba;
M.utils.getParameterValue = MutilsModule.getParameterValue;
M.utils.getResolutionFromScale = MutilsModule.getResolutionFromScale;
M.utils.getRgba = MutilsModule.getRgba;
M.utils.getScaleFromResolution = MutilsModule.getScaleFromResolution;
M.utils.getTextFromHtml = MutilsModule.getTextFromHtml;
M.utils.getWMSGetCapabilitiesUrl = MutilsModule.getWMSGetCapabilitiesUrl;
M.utils.getWMTSGetCapabilitiesUrl = MutilsModule.getWMTSGetCapabilitiesUrl;
M.utils.htmlToString = MutilsModule.htmlToString;
M.utils.includes = MutilsModule.includes;
M.utils.inverseColor = MutilsModule.inverseColor;
M.utils.isArray = MutilsModule.isArray;
M.utils.isBoolean = MutilsModule.isBoolean;
M.utils.isDynamic = MutilsModule.isDynamic;
M.utils.isFunction = MutilsModule.isFunction;
M.utils.isGeometryType = MutilsModule.isGeometryType;
M.utils.isNull = MutilsModule.isNull;
M.utils.isNullOrEmpty = MutilsModule.isNullOrEmpty;
M.utils.isObject = MutilsModule.isObject;
M.utils.isString = MutilsModule.isString;
M.utils.isUndefined = MutilsModule.isUndefined;
M.utils.isUrl = MutilsModule.isUrl;
M.utils.modifySVG = MutilsModule.modifySVG;
M.utils.normalize = MutilsModule.normalize;
M.utils.returnPositionHtmlElement = MutilsModule.returnPositionHtmlElement;
M.utils.rgbToHex = MutilsModule.rgbToHex;
M.utils.rgbaToHex = MutilsModule.rgbaToHex;
M.utils.sameUrl = MutilsModule.sameUrl;
M.utils.setDynamicLegend = MutilsModule.setDynamicLegend;
M.utils.stringToHtml = MutilsModule.stringToHtml;
M.utils.stringifyFunctions = MutilsModule.stringifyFunctions;
M.utils.styleComparator = MutilsModule.styleComparator;
M.utils.transfomContent = MutilsModule.transfomContent;
M.window = $M$window;
ol.Collection = $ol$Collection;
ol.Feature = $ol$Feature;
ol.Geolocation = $ol$Geolocation;
ol.Image = {};
ol.Image.decode = olImageModule.decode;
ol.Image.load = olImageModule.load;
ol.Kinetic = $ol$Kinetic;
ol.Map = $ol$Map;
ol.Object = $ol$Object;
ol.Observable = $ol$Observable;
ol.Observable.unByKey = olObservableModule.unByKey;
ol.Overlay = $ol$Overlay;
ol.View = $ol$View;
ol.array = {};
ol.array.stableSort = olarrayModule.stableSort;
ol.color = {};
ol.color.asArray = olcolorModule.asArray;
ol.color.asString = olcolorModule.asString;
ol.colorlike = {};
ol.colorlike.asColorLike = olcolorlikeModule.asColorLike;
ol.control = {};
ol.control.Attribution = $ol$control$Attribution;
ol.control.Control = $ol$control$Control;
ol.control.FullScreen = $ol$control$FullScreen;
ol.control.MousePosition = $ol$control$MousePosition;
ol.control.OverviewMap = $ol$control$OverviewMap;
ol.control.Rotate = $ol$control$Rotate;
ol.control.ScaleLine = $ol$control$ScaleLine;
ol.control.Zoom = $ol$control$Zoom;
ol.control.ZoomSlider = $ol$control$ZoomSlider;
ol.control.ZoomToExtent = $ol$control$ZoomToExtent;
ol.control.defaults = {};
ol.control.defaults.defaults = olcontroldefaultsModule.defaults;
ol.coordinate = {};
ol.coordinate.add = olcoordinateModule.add;
ol.coordinate.createStringXY = olcoordinateModule.createStringXY;
ol.coordinate.format = olcoordinateModule.format;
ol.coordinate.rotate = olcoordinateModule.rotate;
ol.coordinate.toStringHDMS = olcoordinateModule.toStringHDMS;
ol.coordinate.toStringXY = olcoordinateModule.toStringXY;
ol.easing = {};
ol.easing.easeIn = oleasingModule.easeIn;
ol.easing.easeOut = oleasingModule.easeOut;
ol.easing.inAndOut = oleasingModule.inAndOut;
ol.easing.linear = oleasingModule.linear;
ol.easing.upAndDown = oleasingModule.upAndDown;
ol.events = {};
ol.events.condition = {};
ol.events.condition.altKeyOnly = oleventsconditionModule.altKeyOnly;
ol.events.condition.altShiftKeysOnly = oleventsconditionModule.altShiftKeysOnly;
ol.events.condition.always = oleventsconditionModule.always;
ol.events.condition.click = oleventsconditionModule.click;
ol.events.condition.doubleClick = oleventsconditionModule.doubleClick;
ol.events.condition.focus = oleventsconditionModule.focus;
ol.events.condition.mouseOnly = oleventsconditionModule.mouseOnly;
ol.events.condition.never = oleventsconditionModule.never;
ol.events.condition.noModifierKeys = oleventsconditionModule.noModifierKeys;
ol.events.condition.penOnly = oleventsconditionModule.penOnly;
ol.events.condition.platformModifierKey = oleventsconditionModule.platformModifierKey;
ol.events.condition.platformModifierKeyOnly = oleventsconditionModule.platformModifierKeyOnly;
ol.events.condition.pointerMove = oleventsconditionModule.pointerMove;
ol.events.condition.primaryAction = oleventsconditionModule.primaryAction;
ol.events.condition.shiftKeyOnly = oleventsconditionModule.shiftKeyOnly;
ol.events.condition.singleClick = oleventsconditionModule.singleClick;
ol.events.condition.targetNotEditable = oleventsconditionModule.targetNotEditable;
ol.events.condition.touchOnly = oleventsconditionModule.touchOnly;
ol.extent = {};
ol.extent.applyTransform = olextentModule.applyTransform;
ol.extent.boundingExtent = olextentModule.boundingExtent;
ol.extent.buffer = olextentModule.buffer;
ol.extent.containsCoordinate = olextentModule.containsCoordinate;
ol.extent.containsExtent = olextentModule.containsExtent;
ol.extent.containsXY = olextentModule.containsXY;
ol.extent.createEmpty = olextentModule.createEmpty;
ol.extent.equals = olextentModule.equals;
ol.extent.extend = olextentModule.extend;
ol.extent.getArea = olextentModule.getArea;
ol.extent.getBottomLeft = olextentModule.getBottomLeft;
ol.extent.getBottomRight = olextentModule.getBottomRight;
ol.extent.getCenter = olextentModule.getCenter;
ol.extent.getHeight = olextentModule.getHeight;
ol.extent.getIntersection = olextentModule.getIntersection;
ol.extent.getSize = olextentModule.getSize;
ol.extent.getTopLeft = olextentModule.getTopLeft;
ol.extent.getTopRight = olextentModule.getTopRight;
ol.extent.getWidth = olextentModule.getWidth;
ol.extent.intersects = olextentModule.intersects;
ol.extent.isEmpty = olextentModule.isEmpty;
ol.featureloader = {};
ol.featureloader.setWithCredentials = olfeatureloaderModule.setWithCredentials;
ol.featureloader.xhr = olfeatureloaderModule.xhr;
ol.format = {};
ol.format.EsriJSON = $ol$format$EsriJSON;
ol.format.Feature = $ol$format$Feature;
ol.format.GML = $ol$format$GML;
ol.format.GML2 = $ol$format$GML2;
ol.format.GML3 = $ol$format$GML3;
ol.format.GML32 = $ol$format$GML32;
ol.format.GMLBase = $ol$format$GMLBase;
ol.format.GPX = $ol$format$GPX;
ol.format.GeoJSON = $ol$format$GeoJSON;
ol.format.IGC = $ol$format$IGC;
ol.format.IIIFInfo = $ol$format$IIIFInfo;
ol.format.KML = $ol$format$KML;
ol.format.MVT = $ol$format$MVT;
ol.format.OSMXML = $ol$format$OSMXML;
ol.format.Polyline = $ol$format$Polyline;
ol.format.Polyline.decodeDeltas = olformatPolylineModule.decodeDeltas;
ol.format.Polyline.decodeFloats = olformatPolylineModule.decodeFloats;
ol.format.Polyline.encodeDeltas = olformatPolylineModule.encodeDeltas;
ol.format.Polyline.encodeFloats = olformatPolylineModule.encodeFloats;
ol.format.TopoJSON = $ol$format$TopoJSON;
ol.format.WFS = $ol$format$WFS;
ol.format.WFS.writeFilter = olformatWFSModule.writeFilter;
ol.format.WKB = $ol$format$WKB;
ol.format.WKT = $ol$format$WKT;
ol.format.WMSCapabilities = $ol$format$WMSCapabilities;
ol.format.WMSGetFeatureInfo = $ol$format$WMSGetFeatureInfo;
ol.format.WMTSCapabilities = $ol$format$WMTSCapabilities;
ol.format.filter = {};
ol.format.filter.Bbox = $ol$format$filter$Bbox;
ol.format.filter.Contains = $ol$format$filter$Contains;
ol.format.filter.DWithin = $ol$format$filter$DWithin;
ol.format.filter.Disjoint = $ol$format$filter$Disjoint;
ol.format.filter.During = $ol$format$filter$During;
ol.format.filter.EqualTo = $ol$format$filter$EqualTo;
ol.format.filter.GreaterThan = $ol$format$filter$GreaterThan;
ol.format.filter.GreaterThanOrEqualTo = $ol$format$filter$GreaterThanOrEqualTo;
ol.format.filter.Intersects = $ol$format$filter$Intersects;
ol.format.filter.IsBetween = $ol$format$filter$IsBetween;
ol.format.filter.IsLike = $ol$format$filter$IsLike;
ol.format.filter.IsNull = $ol$format$filter$IsNull;
ol.format.filter.LessThan = $ol$format$filter$LessThan;
ol.format.filter.LessThanOrEqualTo = $ol$format$filter$LessThanOrEqualTo;
ol.format.filter.Not = $ol$format$filter$Not;
ol.format.filter.NotEqualTo = $ol$format$filter$NotEqualTo;
ol.format.filter.Or = $ol$format$filter$Or;
ol.format.filter.Within = $ol$format$filter$Within;
ol.format.filter.and = olformatfilterModule.and;
ol.format.filter.bbox = olformatfilterModule.bbox;
ol.format.filter.between = olformatfilterModule.between;
ol.format.filter.contains = olformatfilterModule.contains;
ol.format.filter.disjoint = olformatfilterModule.disjoint;
ol.format.filter.during = olformatfilterModule.during;
ol.format.filter.dwithin = olformatfilterModule.dwithin;
ol.format.filter.equalTo = olformatfilterModule.equalTo;
ol.format.filter.greaterThan = olformatfilterModule.greaterThan;
ol.format.filter.greaterThanOrEqualTo = olformatfilterModule.greaterThanOrEqualTo;
ol.format.filter.intersects = olformatfilterModule.intersects;
ol.format.filter.isNull = olformatfilterModule.isNull;
ol.format.filter.lessThan = olformatfilterModule.lessThan;
ol.format.filter.lessThanOrEqualTo = olformatfilterModule.lessThanOrEqualTo;
ol.format.filter.like = olformatfilterModule.like;
ol.format.filter.not = olformatfilterModule.not;
ol.format.filter.notEqualTo = olformatfilterModule.notEqualTo;
ol.format.filter.or = olformatfilterModule.or;
ol.format.filter.within = olformatfilterModule.within;
ol.geom = {};
ol.geom.Circle = $ol$geom$Circle;
ol.geom.Geometry = $ol$geom$Geometry;
ol.geom.GeometryCollection = $ol$geom$GeometryCollection;
ol.geom.LineString = $ol$geom$LineString;
ol.geom.LinearRing = $ol$geom$LinearRing;
ol.geom.MultiLineString = $ol$geom$MultiLineString;
ol.geom.MultiPoint = $ol$geom$MultiPoint;
ol.geom.MultiPolygon = $ol$geom$MultiPolygon;
ol.geom.Point = $ol$geom$Point;
ol.geom.Polygon = $ol$geom$Polygon;
ol.geom.Polygon.circular = olgeomPolygonModule.circular;
ol.geom.Polygon.fromCircle = olgeomPolygonModule.fromCircle;
ol.geom.Polygon.fromExtent = olgeomPolygonModule.fromExtent;
ol.geom.SimpleGeometry = $ol$geom$SimpleGeometry;
ol.has = {};
ol.has.DEVICE_PIXEL_RATIO = olhasModule.DEVICE_PIXEL_RATIO;
ol.interaction = {};
ol.interaction.DblClickDragZoom = $ol$interaction$DblClickDragZoom;
ol.interaction.DoubleClickZoom = $ol$interaction$DoubleClickZoom;
ol.interaction.DragAndDrop = $ol$interaction$DragAndDrop;
ol.interaction.DragBox = $ol$interaction$DragBox;
ol.interaction.DragPan = $ol$interaction$DragPan;
ol.interaction.DragRotate = $ol$interaction$DragRotate;
ol.interaction.DragRotateAndZoom = $ol$interaction$DragRotateAndZoom;
ol.interaction.DragZoom = $ol$interaction$DragZoom;
ol.interaction.Draw = $ol$interaction$Draw;
ol.interaction.Draw.createBox = olinteractionDrawModule.createBox;
ol.interaction.Draw.createRegularPolygon = olinteractionDrawModule.createRegularPolygon;
ol.interaction.Extent = $ol$interaction$Extent;
ol.interaction.Interaction = $ol$interaction$Interaction;
ol.interaction.KeyboardPan = $ol$interaction$KeyboardPan;
ol.interaction.KeyboardZoom = $ol$interaction$KeyboardZoom;
ol.interaction.Link = $ol$interaction$Link;
ol.interaction.Modify = $ol$interaction$Modify;
ol.interaction.MouseWheelZoom = $ol$interaction$MouseWheelZoom;
ol.interaction.PinchRotate = $ol$interaction$PinchRotate;
ol.interaction.PinchZoom = $ol$interaction$PinchZoom;
ol.interaction.Pointer = $ol$interaction$Pointer;
ol.interaction.Select = $ol$interaction$Select;
ol.interaction.Snap = $ol$interaction$Snap;
ol.interaction.Translate = $ol$interaction$Translate;
ol.interaction.defaults = {};
ol.interaction.defaults.defaults = olinteractiondefaultsModule.defaults;
ol.layer = {};
ol.layer.Base = $ol$layer$Base;
ol.layer.BaseImage = $ol$layer$BaseImage;
ol.layer.BaseTile = $ol$layer$BaseTile;
ol.layer.BaseVector = $ol$layer$BaseVector;
ol.layer.Graticule = $ol$layer$Graticule;
ol.layer.Group = $ol$layer$Group;
ol.layer.Heatmap = $ol$layer$Heatmap;
ol.layer.Image = $ol$layer$Image;
ol.layer.Layer = $ol$layer$Layer;
ol.layer.Tile = $ol$layer$Tile;
ol.layer.Vector = $ol$layer$Vector;
ol.layer.VectorImage = $ol$layer$VectorImage;
ol.layer.VectorTile = $ol$layer$VectorTile;
ol.layer.WebGLTile = $ol$layer$WebGLTile;
ol.loadingstrategy = {};
ol.loadingstrategy.all = olloadingstrategyModule.all;
ol.loadingstrategy.bbox = olloadingstrategyModule.bbox;
ol.loadingstrategy.tile = olloadingstrategyModule.tile;
ol.proj = {};
ol.proj.Projection = $ol$proj$Projection;
ol.proj.Units = {};
ol.proj.Units.METERS_PER_UNIT = olprojUnitsModule.METERS_PER_UNIT;
ol.proj.addCoordinateTransforms = olprojModule.addCoordinateTransforms;
ol.proj.addEquivalentProjections = olprojModule.addEquivalentProjections;
ol.proj.addProjection = olprojModule.addProjection;
ol.proj.clearUserProjection = olprojModule.clearUserProjection;
ol.proj.equivalent = olprojModule.equivalent;
ol.proj.fromLonLat = olprojModule.fromLonLat;
ol.proj.get = olprojModule.get;
ol.proj.getPointResolution = olprojModule.getPointResolution;
ol.proj.getTransform = olprojModule.getTransform;
ol.proj.getUserProjection = olprojModule.getUserProjection;
ol.proj.proj4 = {};
ol.proj.proj4.epsgLookupMapTiler = olprojproj4Module.epsgLookupMapTiler;
ol.proj.proj4.fromEPSGCode = olprojproj4Module.fromEPSGCode;
ol.proj.proj4.register = olprojproj4Module.register;
ol.proj.proj4.setEPSGLookup = olprojproj4Module.setEPSGLookup;
ol.proj.setUserProjection = olprojModule.setUserProjection;
ol.proj.toLonLat = olprojModule.toLonLat;
ol.proj.transform = olprojModule.transform;
ol.proj.transformExtent = olprojModule.transformExtent;
ol.proj.useGeographic = olprojModule.useGeographic;
ol.render = {};
ol.render.Feature = {};
ol.render.Feature.toFeature = olrenderFeatureModule.toFeature;
ol.render.Feature.toGeometry = olrenderFeatureModule.toGeometry;
ol.render.VectorContext = $ol$render$VectorContext;
ol.render.getRenderPixel = olrenderModule.getRenderPixel;
ol.render.getVectorContext = olrenderModule.getVectorContext;
ol.render.toContext = olrenderModule.toContext;
ol.renderer = {};
ol.renderer.Composite = $ol$renderer$Composite;
ol.renderer.canvas = {};
ol.renderer.canvas.ImageLayer = $ol$renderer$canvas$ImageLayer;
ol.renderer.canvas.TileLayer = $ol$renderer$canvas$TileLayer;
ol.renderer.canvas.VectorImageLayer = $ol$renderer$canvas$VectorImageLayer;
ol.renderer.canvas.VectorLayer = $ol$renderer$canvas$VectorLayer;
ol.renderer.canvas.VectorTileLayer = $ol$renderer$canvas$VectorTileLayer;
ol.renderer.webgl = {};
ol.renderer.webgl.PointsLayer = $ol$renderer$webgl$PointsLayer;
ol.renderer.webgl.TileLayer = $ol$renderer$webgl$TileLayer;
ol.size = {};
ol.size.toSize = olsizeModule.toSize;
ol.source = {};
ol.source.BingMaps = $ol$source$BingMaps;
ol.source.CartoDB = $ol$source$CartoDB;
ol.source.Cluster = $ol$source$Cluster;
ol.source.DataTile = $ol$source$DataTile;
ol.source.GeoTIFF = $ol$source$GeoTIFF;
ol.source.IIIF = $ol$source$IIIF;
ol.source.Image = $ol$source$Image;
ol.source.ImageArcGISRest = $ol$source$ImageArcGISRest;
ol.source.ImageCanvas = $ol$source$ImageCanvas;
ol.source.ImageMapGuide = $ol$source$ImageMapGuide;
ol.source.ImageStatic = $ol$source$ImageStatic;
ol.source.ImageWMS = $ol$source$ImageWMS;
ol.source.OGCMapTile = $ol$source$OGCMapTile;
ol.source.OGCVectorTile = $ol$source$OGCVectorTile;
ol.source.OSM = $ol$source$OSM;
ol.source.OSM.ATTRIBUTION = olsourceOSMModule.ATTRIBUTION;
ol.source.Raster = $ol$source$Raster;
ol.source.Source = $ol$source$Source;
ol.source.StadiaMaps = $ol$source$StadiaMaps;
ol.source.Tile = $ol$source$Tile;
ol.source.TileArcGISRest = $ol$source$TileArcGISRest;
ol.source.TileDebug = $ol$source$TileDebug;
ol.source.TileImage = $ol$source$TileImage;
ol.source.TileJSON = $ol$source$TileJSON;
ol.source.TileWMS = $ol$source$TileWMS;
ol.source.UTFGrid = $ol$source$UTFGrid;
ol.source.Vector = $ol$source$Vector;
ol.source.VectorTile = $ol$source$VectorTile;
ol.source.WMTS = $ol$source$WMTS;
ol.source.WMTS.optionsFromCapabilities = olsourceWMTSModule.optionsFromCapabilities;
ol.source.XYZ = $ol$source$XYZ;
ol.source.Zoomify = $ol$source$Zoomify;
ol.source.arcgisRest = {};
ol.source.arcgisRest.createLoader = olsourcearcgisRestModule.createLoader;
ol.source.mapguide = {};
ol.source.mapguide.createLoader = olsourcemapguideModule.createLoader;
ol.source.sourcesFromTileGrid = olsourceModule.sourcesFromTileGrid;
ol.source.static = {};
ol.source.static.createLoader = olsourcestaticModule.createLoader;
ol.source.wms = {};
ol.source.wms.createLoader = olsourcewmsModule.createLoader;
ol.sphere = {};
ol.sphere.getArea = olsphereModule.getArea;
ol.sphere.getDistance = olsphereModule.getDistance;
ol.sphere.getLength = olsphereModule.getLength;
ol.style = {};
ol.style.Circle = $ol$style$Circle;
ol.style.Fill = $ol$style$Fill;
ol.style.Icon = $ol$style$Icon;
ol.style.IconImageCache = {};
ol.style.IconImageCache.shared = olstyleIconImageCacheModule.shared;
ol.style.Image = $ol$style$Image;
ol.style.RegularShape = $ol$style$RegularShape;
ol.style.Stroke = $ol$style$Stroke;
ol.style.Style = $ol$style$Style;
ol.style.Text = $ol$style$Text;
ol.tilegrid = {};
ol.tilegrid.TileGrid = $ol$tilegrid$TileGrid;
ol.tilegrid.WMTS = $ol$tilegrid$WMTS;
ol.tilegrid.WMTS.createFromCapabilitiesMatrixSet = oltilegridWMTSModule.createFromCapabilitiesMatrixSet;
ol.tilegrid.createXYZ = oltilegridModule.createXYZ;
ol.transform = {};
ol.transform.composeCssTransform = oltransformModule.composeCssTransform;
ol.util = {};
ol.util.getUid = olutilModule.getUid;
ol.webgl = {};
ol.webgl.ARRAY_BUFFER = olwebglModule.ARRAY_BUFFER;
ol.webgl.DYNAMIC_DRAW = olwebglModule.DYNAMIC_DRAW;
ol.webgl.ELEMENT_ARRAY_BUFFER = olwebglModule.ELEMENT_ARRAY_BUFFER;
ol.webgl.STATIC_DRAW = olwebglModule.STATIC_DRAW;
ol.webgl.STREAM_DRAW = olwebglModule.STREAM_DRAW;
ol.xml = {};
ol.xml.getAllTextContent = olxmlModule.getAllTextContent;
ol.xml.parse = olxmlModule.parse;
ol.xml.registerDocument = olxmlModule.registerDocument;
ol.xml.registerXMLSerializer = olxmlModule.registerXMLSerializer;
window['Handlebars'] = require('handlebars');
window['proj4'] = require('proj4');
M.plugin = {}